html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  overflow: scroll;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  border-width: 2px;
  background-color: #E9E9E9;
}
::-webkit-scrollbar-thumb:hover{
  background: rgba(157, 165, 183, 0.7);
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background-color: transparent;
}