@import 'scss/reset';
@import 'antd/dist/reset.css';

html, body {
  width: 100% !important;
  height: 100%;
  font-size: 16px;
  overflow: hidden;

  #root {
    width: 100%;
    height: 100%;
  }
}

.page-content {
  width: 100%;
  height: 100%;
}
.page-loading {
  margin-top: 20%;
  text-align: center;
}
.ant-modal-root {
  .ant-btn-primary {
    background-color: #ff842c;
    &:hover {
      color: #FFF !important;
      background-color: #ff842c !important;
      border-color: #ff842c !important;
    }
    &:active {
      background-color: #ff842c !important;
    }
    &:focus-visible {
      outline: none;
    }
    
  }
  .ant-btn-default {
    &:hover {
      color: #ff842c !important;
      border-color: #ff842c !important;
    }
  }

  .modal-content-text {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
}
.ant-popover {
  @extend .ant-modal-root
}
.ant-table-cell {
  .ant-space {
    .anticon {
        color: #ff842c;
        font-size: 18px;
        cursor: pointer;
    }
  }
}

.home-gradual-button {
  cursor: pointer;
  border: none;
  border-radius: 27px;
  background: linear-gradient(to right, #55B5FF, #534AFF);
}